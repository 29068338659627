import CheckinForm from "./pages/CheckinForm";
import CheckinResult from "./pages/CheckinResult";
import NotFound from "./pages/NotFound";
import { Switch, Route, Redirect } from "react-router-dom";

function App() {
  return (
    <Switch>
      <Route path="/checkin-form/:activityId" component={CheckinForm}/>
      <Route path="/checkin-result" component={CheckinResult}/>
      <Route path="/404" component={NotFound}/>
      <Redirect to="/404"/>
    </Switch>
  );
}

export default App;
