//动态设置html的字体大小，默认html{font-size:50px}

(function flexible (window, document) {
    function IsPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    }
	var docEl = document.documentElement
    // console.log(docEl)
	var dpr = window.devicePixelRatio || 1
    
	function setRemUnit () {
      var isPc=IsPC()
      var w=''
      if(isPc){
        // w = 500
				w = docEl.clientWidth >= 400 ? 400 : docEl.clientWidth
      }else{
        w = docEl.clientWidth >= 768 ? 768 : docEl.clientWidth
         
	  }
	  
      var rem = w / 7.5
      docEl.style.fontSize = rem + 'px' 
	  
	}
	
	setRemUnit()
	// reset rem unit on page resize
	window.addEventListener('resize', setRemUnit)
	window.addEventListener('pageshow', function (e) {
	  if (e.persisted) {
	    setRemUnit()
	  }
	})
	// detect 0.5px supports
	if (dpr >= 2) {
	  var fakeBody = document.createElement('body')
	  var testElement = document.createElement('div')
	  testElement.style.border = '.5px solid transparent'
	  fakeBody.appendChild(testElement)
	  docEl.appendChild(fakeBody)
	  if (testElement.offsetHeight === 1) {
	    docEl.classList.add('hairlines')
	  }
	  docEl.removeChild(fakeBody)
	}
}(window, document))