import { CUR_ENV } from '../config';

const preStr = 'checkin_' + CUR_ENV + '_'

export const storage = {
  activityId: preStr + 'activity_id'
}

/*设置cookie*/
export function setCookie(key, value, t) {
	var oDate = new Date();
    oDate.setDate(oDate.getDate() + t);
    value = encodeURIComponent(value);
	document.cookie = key + '=' + value + ';expires=' + oDate.toGMTString()+';path=/';
}

/*获取cookie*/
export function getCookie (key) {
	var arr1 = document.cookie.split('; ');
	for (var i=0; i<arr1.length; i++) {
		var arr2 = arr1[i].split('=');
		if ( arr2[0] === key ) {
			return decodeURIComponent(arr2[1]);
		}
	}
}

/*清除cookie*/
export function removeCookie(key) {
	this.setCookie(key, '', -1);
}


// 删除所有 cookie
export function removeAllCookie() {
    let expires = new Date(0).toUTCString();
    let cookieStr = document.cookie;
    let cookieList = cookieStr.split('; ');
    for (let i = 0; i < cookieList.length; i ++) {
      let key = cookieList[i].split('=')[0]
      document.cookie = key + '=; expires=' + expires
    }
}