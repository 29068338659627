/**
 * 当前环境 
 * local
 * dev
 * test
 * prod
*/
export const CUR_ENV = 'test';

/**
 * 接口前缀
*/
const base_apis = {
  local: 'http://10.10.1.68:10050',
  dev: 'https://api.dev.ysintelligent.com/fzzht/web',
  test: 'https://api.test.ysintelligent.com/fzzht/web',
  prod: 'https://api.ysintelligent.com/fzzht/web',
}

const yjj_base_apis = {
  local: 'https://api.dev.ysintelligent.com/yijiajia_app',
  dev: 'https://api.dev.ysintelligent.com/yijiajia_app',
  test: 'https://api.test.ysintelligent.com/yijiajia_app',
  prod: 'https://api.ysintelligent.com/yijiajia_app',
}


/**
 * oss静态资源地址前缀
*/
const oss_urls = {
  local: 'https://oss.dev.yijiajiayun.com',
  dev: 'https://oss.dev.yijiajiayun.com',
  test: 'https://oss.test.yijiajiayun.com',
  prod: 'https://oss.yijiajiayun.com',
}

export const BASE_API = base_apis[CUR_ENV];
export const YJJ_BASE_API = yjj_base_apis[CUR_ENV];
export const OSS_URL = oss_urls[CUR_ENV];