import styles from './index.module.less'

export default function NotFound() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.text1}>404</div>
      <p className={styles.text2}>
        抱歉，您访问的页面不存在
      </p>
    </div>
  )
}